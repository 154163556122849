import React from 'react'
import Layout from '../../components/Layout'

export default () => (
  <Layout>
    <section className="section">
      <div className="container">
        <div className="columns is-desktop">
          <div className="column is-two-thirds-desktop">
            <div id="classAnchor" className="section" style={{
              maxWidth: '700px',
              margin: '0 0 0 auto'
            }}><h1>Thanks for reaching out!</h1>
              <p>We'll get back to you shortly.</p>


            </div>
          </div>
          <div className="column is-one-third-desktop">
            <div className="section">
              <h6>LOCATION</h6>
              <p className="has-text-weight-bold smaller">Kansas City, MO</p>
              <h6 className="mt-4">OFFICE HOURS</h6>
              <p className="has-text-weight-bold smaller">Monday - Friday<br />
                  8AM - 5PM</p>
              <h6>CONTACT</h6>
              <p className="has-text-weight-bold smaller">contact@babeshelpinbabes.com<br />
                  (913) 957-6739</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)
